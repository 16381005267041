import React from "react";

interface EstateProps {
  mainImage: string;
  price: string;
  pricePerMeter: string;
  location: string;
  type: string;
  roomCount: string;
  surface: string;
}

const EstateCardComponent: React.FC<EstateProps> = ({
  mainImage,
  price,
  pricePerMeter,
  location,
  type,
  roomCount,
  surface,
}) => {
  return (
    <div className="estateCard bg-gray-800 text-white rounded-lg overflow-hidden w-[350px]">
      <div className="relative">
        <img
          className="estateImage w-full h-48 object-cover"
          src={mainImage}
          alt="Estate Image"
        />
      </div>
      <div className="infoContainer p-4">
        <div className="price text-2xl font-bold">
          {price.toLocaleString() + " zł"}
        </div>
        <div className="pricePerMeter text-sm text-gray-400">
          {pricePerMeter}
        </div>
        <div className="location text-lg mt-2">{location}</div>
        <div className="type text-sm text-gray-500">{type}</div>
        <div className="details flex justify-between mt-4">
          <div>
            {parseInt(roomCount) != 0
              ? roomCount + (parseInt(roomCount) >= 5 ? " Pokoi" : " Pokoje")
              : ""}
          </div>
          <div>{surface} m²</div>
        </div>
      </div>
    </div>
  );
};

export default EstateCardComponent;
