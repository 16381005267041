import React from "react";
import { useState } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal = ({ isOpen, onClose }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error: any) {
      setError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal h-[100vh] w-[100vw] flex justify-center items-center">
      <div className="modal-content">
        {error && error == "Firebase: Error (auth/invalid-credential)." ? (
          <p className="error">Podano błędne dane!</p>
        ) : (
          <p>{error}</p>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Hasło"
              className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
            />
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="block w-[140px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;
