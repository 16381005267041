import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import ReactMarkdown from "react-markdown";
import {
  UilBold,
  UilEdit,
  UilItalic,
  UilPlusCircle,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";

interface Estate {
  id: string;
  name: string;
  location: string;
  cost: string;
  description: string;
  roomCount: string;
  surface: string;
  mainImageUrl: string;
  mediaUrls: string[] | null;
  type:
    | "Dom na Sprzedaż"
    | "Dom na Wynajem"
    | "Mieszkanie na Sprzedaż"
    | "Mieszkanie na Wynajem"
    | "Działka na Sprzedaż"
    | "Działka na Wynajem"
    | "Lokal na Sprzedaż"
    | "Lokal na Wynajem"
    | "Obiekt na Sprzedaż"
    | "Obiekt na Wynajem";
}

interface Props {
  estateId: string;
}

const EstateEdit = ({ estateId }: Props) => {
  const [estate, setEstate] = useState<Estate | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  const fetchEstate = async (estateId: string) => {
    try {
      const estateDocRef = doc(db, "estates", estateId);
      const estateDoc = await getDoc(estateDocRef);

      if (estateDoc.exists()) {
        const estateData = estateDoc.data() as Estate;
        if (!estateData.id) {
          estateData.id = estateDoc.id;
        }

        const mainImageRef = ref(storage, estateData.mainImageUrl);
        const mainImageUrl = await getDownloadURL(mainImageRef);

        let mediaUrls: string[] = [];
        if (estateData.mediaUrls) {
          mediaUrls = await Promise.all(
            estateData.mediaUrls.map(async (mediaPath) => {
              const mediaRef = ref(storage, mediaPath);
              return await getDownloadURL(mediaRef);
            })
          );
        }

        setEstate({ ...estateData, mainImageUrl, mediaUrls });
      } else {
        setError("Estate not found");
      }
    } catch (error) {
      console.error("Error fetching estate:", error);
      setError("Failed to fetch estate");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (field: string, value: string | string[]) => {
    if (estate && estate.id) {
      try {
        const estateDocRef = doc(db, "estates", estate.id);
        await updateDoc(estateDocRef, { [field]: value });
        setEstate({ ...estate, [field]: value });
      } catch (error) {
        console.error("Error updating estate:", error);
        setError("Failed to update estate");
      }
    } else {
      console.error("Estate ID is undefined");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddImage = async () => {
    if (selectedFile && estate) {
      setUploading(true);
      const imageRef = ref(
        storage,
        `estates/${estate.id}/media/${selectedFile.name}`
      );
      try {
        await uploadBytes(imageRef, selectedFile);
        const newImageUrl = await getDownloadURL(imageRef);

        const updatedMediaUrls = estate.mediaUrls
          ? [...estate.mediaUrls, newImageUrl]
          : [newImageUrl];
        await handleSave("mediaUrls", updatedMediaUrls);

        setSelectedFile(null);
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDeleteImage = async (imageUrl: string) => {
    if (estate && estate.mediaUrls) {
      try {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
        const updatedMediaUrls = estate.mediaUrls.filter(
          (url) => url !== imageUrl
        );
        await handleSave("mediaUrls", updatedMediaUrls);
      } catch (error) {
        console.error("Error deleting image:", error);
        setError("Failed to delete image");
      }
    }
  };

  const handleFieldClick = (field: string, currentValue: string) => {
    setEditingField(field);
    setNewValue(currentValue);
  };

  const addEmptyLines = (text: string) => {
    return text.endsWith("\n") ? text : text + "\n";
  };

  const handleBlur = () => {
    if (editingField && newValue !== estate?.[editingField as keyof Estate]) {
      const newValueWithEmptyLines = addEmptyLines(newValue);
      handleSave(editingField, newValueWithEmptyLines);
    }
    setEditingField(null);
  };

  const insertMarkdown = (startTag: string, endTag: string = "") => {
    const textarea = document.getElementById(
      "markdown-textarea"
    ) as HTMLTextAreaElement | null;

    if (!textarea) return;

    const start = textarea?.selectionStart;
    const end = textarea?.selectionEnd;
    const text = textarea?.value;

    const selectedText = text.substring(start, end);

    const isHeader = startTag.startsWith("#");

    const needsNewLineBefore =
      isHeader && (start === 0 || text[start - 1] !== "\n");
    const needsNewLineAfter =
      isHeader && (end === text.length || text[end] !== "\n");

    const newText =
      text.substring(0, start) +
      (needsNewLineBefore ? "\n" : "") +
      startTag +
      selectedText +
      endTag +
      (needsNewLineAfter ? "\n" : "") +
      text.substring(end);

    setNewValue(newText);

    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(
        start + startTag.length + (needsNewLineBefore ? 1 : 0),
        end + startTag.length + (needsNewLineBefore ? 1 : 0)
      );
    }, 0);
  };

  useEffect(() => {
    if (selectedFile) {
      handleAddImage();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (estateId) {
      fetchEstate(estateId);
    }
  }, [estateId]);
  const [markdownText, setMarkdownText] = useState(estate?.description);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-end w-[400px]">
        <div>
          {editingField === "location" ? (
            <>
              <label>Lokacja: </label>
              <input
                className="text-black"
                type="text"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                onClick={() =>
                  handleFieldClick("location", estate?.location || "")
                }
              >
                {"Lokacja: " + estate?.location || "Brak danych"}
              </p>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() =>
                  handleFieldClick("location", estate?.location || "")
                }
              />
            </div>
          )}
        </div>
        <div>
          {editingField === "cost" ? (
            <>
              <label>Cena (zł): </label>
              <input
                className="text-black"
                type="number"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p onClick={() => handleFieldClick("cost", estate?.cost || "")}>
                {"Cena (zł): " + estate?.cost || "Brak danych"}
              </p>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() => handleFieldClick("cost", estate?.cost || "")}
              />
            </div>
          )}
        </div>
        <div>
          {editingField === "surface" ? (
            <>
              <label>Powierzchnia (m²): </label>
              <input
                className="text-black"
                type="number"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                onClick={() =>
                  handleFieldClick("surface", estate?.surface || "")
                }
              >
                {"Powierzchnia (m²): " + estate?.surface || "Brak danych"}
              </p>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() =>
                  handleFieldClick("surface", estate?.surface || "")
                }
              />
            </div>
          )}
        </div>
        <div>
          {editingField === "roomCount" ? (
            <>
              <label>Ilość pokoi: </label>
              <input
                className="text-black"
                type="number"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                onClick={() =>
                  handleFieldClick("roomCount", estate?.roomCount || "")
                }
              >
                {"Ilość pokoi: " + estate?.roomCount || "Brak danych"}
              </p>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() =>
                  handleFieldClick("roomCount", estate?.roomCount || "")
                }
              />
            </div>
          )}
        </div>
        <div>
          {editingField === "type" ? (
            <>
              <label>Typ nieruchomości: </label>
              <select
                className="text-black"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              >
                <option value="Dom na Sprzedaż">Dom na Sprzedaż</option>
                <option value="Dom na Wynajem">Dom na Wynajem</option>
                <option value="Mieszkanie na Sprzedaż">
                  Mieszkanie na Sprzedaż
                </option>
                <option value="Mieszkanie na Wynajem">
                  Mieszkanie na Wynajem
                </option>
                <option value="Działka na Sprzedaż">Działka na Sprzedaż</option>
                <option value="Działka na Wynajem">Działka na Wynajem</option>
                <option value="Lokal na Sprzedaż">Lokal na Sprzedaż</option>
                <option value="Lokal na Wynajem">Lokal na Wynajem</option>
                <option value="Obiekt na Sprzedaż">Obiekt na Sprzedaż</option>
                <option value="Obiekt na Wynajem">Obiekt na Wynajem</option>
              </select>
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p onClick={() => handleFieldClick("type", estate?.type || "")}>
                {"Typ nieruchomości: " + estate?.type || "Brak danych"}
              </p>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() => handleFieldClick("type", estate?.type || "")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-[50px]">
        <div className="flex-row justify-center items-center w-full">
          <label className="block">Główna grafika:</label>
          <div className="flex items-center">
            {estate?.mainImageUrl && (
              <img
                src={estate.mainImageUrl}
                alt="Main"
                className="w-[200px] h-auto "
              />
            )}
            <UilEdit
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={() => document.getElementById("fileInput")?.click()}
            />
            <input
              id="fileInput"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-[50px] flex flex-col justify-center items-center">
        <div>
          <label className="block">Dodatkowe grafiki:</label>
          <div className="grid grid-cols-3">
            {estate?.mediaUrls?.map((url, index) => (
              <div key={index} style={{ position: "relative", margin: "8px" }}>
                <img
                  src={url}
                  alt={`Media ${index}`}
                  style={{ width: "150px", height: "auto" }}
                />
                <UilTrashAlt
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => handleDeleteImage(url)}
                />
              </div>
            ))}
          </div>
          {!uploading && selectedFile === null && (
            <div className="flex justify-center items-center">
              <button
                className="p-4 rounded-[20px] bg-secondaryPannelBackground"
                onClick={() =>
                  document.getElementById("additionalImageFileInput")?.click()
                }
              >
                <UilPlusCircle />
              </button>
              <input
                id="additionalImageFileInput"
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-[600px] mt-[50px]">
        <div>
          {editingField === "description" ? (
            <div className="flex flex-col">
              <label>Opis: </label>
              <div className="flex mb-2">
                <button
                  className="mr-2 px-2 py-1 bg-secondaryPannelBackground"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertMarkdown("**", "**");
                  }}
                >
                  <UilBold />
                </button>
                <button
                  className="mr-2 px-2 py-1 bg-secondaryPannelBackground"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertMarkdown("_", "_");
                  }}
                >
                  <UilItalic />
                </button>
                <button
                  className="mr-2 px-2 py-1 bg-secondaryPannelBackground"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertMarkdown("# ");
                  }}
                >
                  H1
                </button>
                <button
                  className="mr-2 px-2 py-1 bg-secondaryPannelBackground"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertMarkdown("## ");
                  }}
                >
                  H2
                </button>
                <button
                  className="mr-2 px-2 py-1 bg-secondaryPannelBackground"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    insertMarkdown("### ");
                  }}
                >
                  H3
                </button>
              </div>
              <textarea
                id="markdown-textarea"
                placeholder="Wprowadź opis w formacie Markdown"
                className="text-black w-[600px] h-[200px]"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onBlur={handleBlur}
                autoFocus
              />
            </div>
          ) : (
            <div className="flex flex-row justify-between items-start">
              <div
                onClick={() =>
                  handleFieldClick("description", estate?.description || "")
                }
              >
                <p>Opis: </p>

                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {estate?.description || "Brak danych"}
                </ReactMarkdown>
              </div>
              <UilEdit
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() => {
                  handleFieldClick("description", estate?.description || "");
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EstateEdit;
