import { useState, useEffect } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Header from "./Components/Header";
import "./App.css";
import LoginModal from "./Components/LoginModal";
import Main from "./Components/Main";
import Footer from "./Components/Footer";

function App() {
  const [isLoginModalOpen, setLoginModalOpen] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      setLoginModalOpen(false);
    }
  }, [user]);

  return (
    <div className="App bg-radial-gradient">
      {!isLoginModalOpen && (
        <>
          <Header userEmail={user.email.split("@")[0]}></Header>
        </>
      )}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </div>
  );
}

export default App;
