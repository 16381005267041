import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import Estates from "./Estates";
import Admin from "./Admin";

interface Props {
  estateNotClicked: boolean;
  setEstateNotClicked: (state: boolean) => void;
}

const Nav = ({ setEstateNotClicked, estateNotClicked }: Props) => {
  const auth = getAuth();

  const [activeComponent, setActiveComponent] = useState<
    "estates" | "admin" | null
  >("estates");

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("Wylogowano pomyślnie");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Błąd podczas wylogowywania:", error);
      });
  };

  const handleShowEstates = () => {
    setEstateNotClicked(true);
    setActiveComponent("estates");
  };

  const handleShowAdmin = () => {
    setEstateNotClicked(true);
    setActiveComponent("admin");
  };

  return (
    <div>
      <div className="nav w-full bg-black h-[50px] p-[12px] xl:p-[7px] flex flex-row justify-end items-center shadow-lg shadow-[rgba(0,0,0,0.5)] relative z-30">
        <div className="md:w-[calc(100vw-106px-36px)] xl:w-[calc(100vw-117px-21px)] text-white flex flex-row justify-end items-center font-['Rubik'] text-lg">
          {/* <p
            onClick={handleShowEstates} // Kliknięcie pokazuje komponent Estates
            className="cursor-pointer hover:text-gray-400"
          >
            Zarządzaj Nieruchomościami
          </p> */}
          {/* <p
            onClick={handleShowAdmin} // Kliknięcie pokazuje komponent Admin
            className="cursor-pointer hover:text-gray-400"
          >
            Lista Administratorów
          </p> */}
          <button
            onClick={handleLogout}
            className="text-white bg-red-500 px-4 py-1 rounded-md hover:bg-red-600 transition-colors"
          >
            Wyloguj się
          </button>
        </div>
      </div>

      <div className="content mt-4">
        {activeComponent === "estates" && (
          <Estates
            estateNotClicked={estateNotClicked}
            setEstateNotClicked={setEstateNotClicked}
          />
        )}{" "}
        {activeComponent === "admin" && <Admin />}
      </div>
    </div>
  );
};

export default Nav;
