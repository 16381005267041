import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
  query,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";
import EstateCardComponent from "./EstateCardComponent";
import { UilEdit, UilTrashAlt, UilPlusCircle } from "@iconscout/react-unicons";
import EstateEdit from "./EstateEdit";

interface Estate {
  id: string;
  location: string;
  cost: string;
  description: string;
  roomCount: string;
  surface: string;
  type:
    | "Dom na Sprzedaż"
    | "Dom na Wynajem"
    | "Mieszkanie na Sprzedaż"
    | "Mieszkanie na Wynajem"
    | "Działka na Sprzedaż"
    | "Działka na Wynajem"
    | "Lokal na Sprzedaż"
    | "Lokal na Wynajem"
    | "Obiekt na Sprzedaż"
    | "Obiekt na Wynajem";
  timestamp: Timestamp;
  mainImageUrl: string;
  secondaryImages: string[];
}

interface Props {
  estateNotClicked: boolean;
  setEstateNotClicked: (state: boolean) => void;
}

const Estates: React.FC<Props> = ({
  estateNotClicked,
  setEstateNotClicked,
}) => {
  const [estates, setEstates] = useState<Estate[]>([]);
  const [newEstate, setNewEstate] = useState<
    Omit<Estate, "id" | "timestamp" | "mainImageUrl" | "secondaryImages">
  >({
    location: "",
    cost: "",
    description: "",
    roomCount: "",
    surface: "",
    type: "Dom na Sprzedaż",
  });
  const [mainImage, setMainImage] = useState<File | null>(null);
  const [secondaryImages, setSecondaryImages] = useState<File[]>([]);

  const [estateClicked, setEstateClicked] = useState<string | null>(null);

  useEffect(() => {
    const fetchEstates = async () => {
      const estatesCollection = collection(db, "estates");
      const estatesQuery = query(
        estatesCollection,
        orderBy("timestamp", "desc")
      );
      const estatesSnapshot = await getDocs(estatesQuery);
      const estatesList = estatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Estate[];
      setEstates(estatesList);
    };

    fetchEstates();
  }, []);

  const uploadImage = async (image: File): Promise<string> => {
    const imageRef = ref(storage, `estates/${image.name}`);
    await uploadBytes(imageRef, image);
    return await getDownloadURL(imageRef);
  };

  const addEstate = async () => {
    if (
      newEstate.location &&
      parseFloat(newEstate.cost) > 0 &&
      parseInt(newEstate.roomCount) >= 0 &&
      parseFloat(newEstate.surface) > 0 &&
      mainImage
    ) {
      const mainImageUrl = await uploadImage(mainImage);
      const secondaryImageUrls = await Promise.all(
        secondaryImages.map((image) => uploadImage(image))
      );
      const estatesCollection = collection(db, "estates");
      const docRef = await addDoc(estatesCollection, {
        ...newEstate,
        cost: parseFloat(newEstate.cost),
        roomCount: parseInt(newEstate.roomCount),
        surface: parseFloat(newEstate.surface),
        mainImageUrl,
        secondaryImages: secondaryImageUrls,
        timestamp: Timestamp.now(),
      });
      setEstates([
        ...estates,
        {
          id: docRef.id,
          ...newEstate,
          mainImageUrl,
          secondaryImages: secondaryImageUrls,
          timestamp: Timestamp.now(),
        },
      ]);
      setNewEstate({
        location: "",
        cost: "",
        description: "",
        roomCount: "",
        surface: "",
        type: "Dom na Sprzedaż",
      });
      setMainImage(null);
      setSecondaryImages([]);
    } else {
      alert("Proszę wypełnić wszystkie pola i dodać główne zdjęcie!");
    }
  };

  const deleteEstate = async (id: string) => {
    await deleteDoc(doc(db, "estates", id));
    setEstates(estates.filter((estate) => estate.id !== id));
  };

  const editEstate = async (id: string) => {
    await deleteDoc(doc(db, "estates", id));
    setEstates(estates.filter((estate) => estate.id !== id));
  };

  useEffect(() => {
    if (estateNotClicked) {
      setEstateClicked(null);
      setEstateNotClicked(false);
    }
  });

  return (
    <div className="w-full min-h-[90dvh] px-[44px] py-[15px] md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative">
      <div className="flex flex-col md:flex-row justify-center items-start text-white">
        {!estateClicked ? (
          <>
            <div className="w-full flex flex-col md:flex-row justify-between items-center md:items-start">
              <div className="bg-secondaryPannelBackground rounded-xl mx-[30px] w-[300px] h-[500px] flex flex-col justify-evenly items-center">
                <input
                  className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                  type="text"
                  placeholder="Lokacja"
                  value={newEstate.location}
                  onChange={(e) =>
                    setNewEstate({ ...newEstate, location: e.target.value })
                  }
                />
                <input
                  className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                  type="text"
                  placeholder="Cena"
                  value={newEstate.cost}
                  onChange={(e) =>
                    setNewEstate({ ...newEstate, cost: e.target.value })
                  }
                />
                <input
                  className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                  type="text"
                  placeholder="Ilość pokoi"
                  value={newEstate.roomCount}
                  onChange={(e) =>
                    setNewEstate({ ...newEstate, roomCount: e.target.value })
                  }
                />
                <input
                  className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                  type="text"
                  placeholder="Powierzchnia (m²)"
                  value={newEstate.surface}
                  onChange={(e) =>
                    setNewEstate({ ...newEstate, surface: e.target.value })
                  }
                />
                <select
                  className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                  value={newEstate.type}
                  onChange={(e) =>
                    setNewEstate({
                      ...newEstate,
                      type: e.target.value as Estate["type"],
                    })
                  }
                >
                  <option value="Dom na Sprzedaż">Dom na Sprzedaż</option>
                  <option value="Dom na Wynajem">Dom na Wynajem</option>
                  <option value="Mieszkanie na Sprzedaż">
                    Mieszkanie na Sprzedaż
                  </option>
                  <option value="Mieszkanie na Wynajem">
                    Mieszkanie na Wynajem
                  </option>
                  <option value="Działka na Sprzedaż">
                    Działka na Sprzedaż
                  </option>
                  <option value="Działka na Wynajem">Działka na Wynajem</option>
                  <option value="Lokal na Sprzedaż">Lokal na Sprzedaż</option>
                  <option value="Lokal na Wynajem">Lokal na Wynajem</option>
                  <option value="Obiekt na Sprzedaż">Obiekt na Sprzedaż</option>
                  <option value="Obiekt na Wynajem">Obiekt na Wynajem</option>
                </select>
                <div className="flex flex-col justify-center items-start mx-[10px]">
                  <label htmlFor="image">Wybierz zdjęcie</label>
                  <input
                    className="block w-[280px] mt-[10px] h-[45px] rounded-[10px] text-[14px] p-[8px] text-black bg-[#D9D9D9] placeholder:text-[#797979]"
                    type="file"
                    id="image"
                    accept="image/*"
                    onChange={(e) => setMainImage(e.target.files?.[0] || null)}
                  />
                </div>

                <button
                  className="bg-zinc-800 p-[15px] rounded-lg hover:bg-zinc-700 duration-150"
                  onClick={addEstate}
                >
                  <UilPlusCircle />
                </button>
              </div>
              <div className="w-full h-full flex flex-col md:flex-row justify-center items-center">
                <div className="w-full h-full flex flex-col items-center justify-center xl:grid xl:grid-cols-2 2xl:grid-cols-3">
                  {estates.map((estate) => (
                    <div className="bg-gray-800 w-[350px] shadow-lg my-[10px] text-white rounded-lg">
                      <EstateCardComponent
                        key={estate.id}
                        mainImage={estate.mainImageUrl}
                        price={estate.cost}
                        pricePerMeter={
                          Math.round(
                            (parseFloat(estate.cost) /
                              parseFloat(estate.surface)) *
                              100
                          ) /
                            100 +
                          " zł/m²"
                        }
                        location={estate.location}
                        type={estate.type}
                        roomCount={estate.roomCount}
                        surface={estate.surface}
                      />
                      <div className="w-[350px] flex flex-row justify-end items-center">
                        <button
                          className="p-[10px]"
                          onClick={() => setEstateClicked(estate.id)}
                        >
                          <UilEdit />
                        </button>
                        <button
                          className="p-[10px]"
                          onClick={() => deleteEstate(estate.id)}
                        >
                          <UilTrashAlt color="red" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <EstateEdit estateId={estateClicked} />
        )}
      </div>
    </div>
  );
};

export default Estates;
