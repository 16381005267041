import React, { useState } from "react";
import Nav from "./Nav";

interface Props {
  userEmail: string;
}

const Header = ({ userEmail }: Props) => {
  const [estateNotClicked, setEstateNotClicked] = useState<boolean>(true);

  return (
    <>
      <div className="header w-full border-b-2 border-solid border-accentYellow bg-black shadow-md shadow-[rgba(239,84,13,0.25)] h-[60px] p-[5px] md:h-[80px] md:p-[12px] xl:p-[7px] flex relative z-50">
        <img
          onClick={() => {
            setEstateNotClicked(true);
          }}
          src={`${process.env.PUBLIC_URL}/logo.jpg`}
          className="aspect-square h-[49px] md:h-[106px] xl:h-[117px] cursor-pointer"
        />
        <div className="text-white hidden ml-auto font-['Rubik'] md:flex md:items-center md:text-2xl md:mr-[20px]">
          <p>Zalogowano jako, {userEmail}</p>
        </div>
        <div className="text-white md:hidden ml-auto font-['Rubik'] flex items-center text-xl mr-[20px]">
          <p>{userEmail}</p>
        </div>
      </div>
      <Nav
        setEstateNotClicked={setEstateNotClicked}
        estateNotClicked={estateNotClicked}
      />
    </>
  );
};

export default Header;
