import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../firebase";

interface AdminData {
  email: string;
  uid: string;
}

const Admin = () => {
  const [admins, setAdmins] = useState<AdminData[]>([]);
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      const adminsCollection = collection(db, "admins");
      const adminSnapshot = await getDocs(adminsCollection);
      const adminList = adminSnapshot.docs.map(
        (doc) => doc.data() as AdminData
      );
      setAdmins(adminList);
    };

    fetchAdmins();
  }, []);

  const addAdmin = async () => {
    const password = Math.random().toString(36).slice(-8);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newAdminEmail,
        password
      );
      const user = userCredential.user;
      await addDoc(collection(db, "admins"), {
        email: newAdminEmail,
        uid: user.uid,
      });

      setGeneratedPassword(password);
      setShowPopup(true);
      setNewAdminEmail("");
    } catch (error) {
      console.error("Błąd przy dodawaniu administratora:", error);
    }
  };

  return (
    <div className="w-full min-h-[90dvh] px-[44px] py-[15px] md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative">
      <h1 className="text-2xl font-bold mb-4">Lista Administratorów</h1>
      <ul>
        {admins.map((admin, index) => (
          <li key={index} className="mb-2">
            {admin.email}
          </li>
        ))}
      </ul>
      {/* <div className="mt-8">
        <h2 className="text-xl font-bold">Dodaj nowego administratora</h2>
        <input
          type="email"
          value={newAdminEmail}
          onChange={(e) => setNewAdminEmail(e.target.value)}
          placeholder="Email nowego administratora"
          className="border px-4 py-2 mt-2"
        />
        <button
          onClick={addAdmin}
          className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Dodaj administratora
        </button>
      </div> */}
      {/* Popup z wygenerowanym hasłem
      {showPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-bold">Administrator dodany!</h3>
            <p>
              Wygenerowane hasło:{" "}
              <span className="font-mono">{generatedPassword}</span>
            </p>
            <button
              onClick={() => setShowPopup(false)}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Zamknij
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Admin;
